/* Modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal content */
.modal-content {
  background-color: #f5f5f5;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  padding: 2%;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 1001;
  padding-right: 4%;
}

/* Close button */
.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 1.5rem;
  cursor: pointer;
}
.close-button:hover{
  color: #9b76e9;
}
/* Open modal button */
.open-modal-button {
  background-color: #9b76e9;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 50%;
}

.open-modal-button:hover {
  background-color: #9b76e9;
}

.form-field {
  margin-bottom: 1rem;
}

.form-field label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.input,
.textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #333; 
}

.textarea {
  resize: vertical;
}

.button {
  background-color: #9b76e9;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.button:hover {
  background-color: #3ead9a;
}

/* Date range container */
.date-range {
  display: flex;
  justify-content: space-between;
  gap: 10%; /* Space between fields */
}

.date-field {
  flex: 1; /* Allow fields to share space equally */
}

.date-field label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
}


/* Responsive Adjustments */
@media screen and (max-width: 768px) {
  .modal-content {
    max-width: 90%;
    padding: 5%; /* Increase padding for smaller screens */
    max-width: 500px;
    padding-right: 7%;
  }

  .date-range{
    flex-wrap: wrap;
  }
  .date-field {
    flex: 1;
  }

  .form-field label {
    font-size: 0.9rem;
  }

  .input,
  .textarea {
    font-size: 0.9rem;
  }

  .button {
    font-size: 0.9rem;
    padding: 0.5rem;
  }
}

@media screen and (max-width: 480px) {
  .modal-content {
    max-width: 90%;
    padding: 10%; /* Increase padding further for extra-small screens */
    padding-right: 15%;
  }

  .date-range{
    flex-wrap: wrap;
  }
  .date-field {
    flex: 1;
    min-width: 45%; /* Ensure both fields fit side-by-side on larger screens */
  }

  .close-button {
    font-size: 1.2rem;
  }

  .form-field label {
    font-size: 0.8rem;
  }

  .input,
  .textarea {
    font-size: 0.8rem;
  }

  .button {
    font-size: 0.8rem;
    padding: 0.4rem;
  }
}
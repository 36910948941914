.services-background {
  background: linear-gradient(to top left, #e5e0f0 50%, #d7cfe8 50%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  position: relative;
  height: calc(100vh-);
  padding-bottom: 20%;
}

.Services-header{
  font-size: 1.5rem;
  color: #000000;
  font-family: 'Brush Script MT', cursive;
  padding-top: 5%;
}

.logo-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  justify-items: center;
  align-items: center;
  height: calc(100vh-);
}

.logo-wrapper {
  position: relative;
  width: 100px;
  height: 100px;
  perspective: 1000px;
  cursor: pointer;
}

.logo-wrapper.flipped .logo-front {
  transform: rotateY(180deg);
}

.logo-wrapper.flipped .logo-back {
  transform: rotateY(0deg);
}

.logo-front,
.logo-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.logo-front {
  transform: rotateY(0deg);
}

.logo-back {
  transform: rotateY(-180deg);
  background: #333;
  color: #fff;
  font-size: 16px;
  text-align: center;
  /* padding: 10px; */
}


.logos {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.logo-text {
  text-align: center;
  font-size: 16px;
  color: #ffffff;
  flex-grow: 1;
  /* text-align: left; */
}

@media (max-width: 768px) {

  .services-background {
    height: 100vh;
    padding-bottom: 30%;
  }

  .Services-header{
    font-size: 1rem;
    padding-top: 10%;
  }

  .logo-container {
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  }

  .logo-wrapper {
    padding: 8px;
    max-width: 150px;
    height: 100px;
  }

  .logos {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 480px) {
  .services-background {
    height: 100vh;
    padding-bottom: 40%;
  }

  .Services-header{
    font-size: 1rem;
    padding-top: 10%;
  }

  .logo-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }

  .logo-wrapper {
    padding: 6px;
    max-width: 150px;
    height: 60px;
  }

  .logos {
    width: 30px;
    height: 30px;
  }
}

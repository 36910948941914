.styled-about-content {
  padding: 0 50px;
  overflow: hidden;
  height: 100%;
  background: linear-gradient(to bottom right, #e5e0f0 50%, #d7cfe8 50%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  z-index: -1;
}

.about-text {
  font-family: 'Georgia', serif;
  line-height: 1.4;
  color: #333;
  font-size: 1.25rem;
  text-align: left;
}

.about-text p:first-of-type {
  font-size: 1.5em;
  font-weight: bold;
}

.sentence-red-first::first-letter {
  font-size: 2em;
  font-weight: bold;
  float: top;
  color: #3ead9a;
}

.list-items li {
  line-height: 1.5;
  padding: 10px;
  margin-bottom: 12px;
}

/* Tablet vibes */
@media (max-width: 768px) {
  .styled-about-content {
    padding: 0 30px;
    padding-bottom: 100px;
  }

  .about-text {
    font-size: 1rem;
    line-height: 1.6;
  }

  .about-text p:first-of-type {
    font-size: 1.2em;
    padding-top: 50px;
  }

  .sentence-red-first::first-letter {
    font-size: 1.5em;
  }

  .list-items li {
    line-height: 1.4;
    padding: 8px;
    margin-bottom: 10px;
  }
}


/* Phones */
@media (max-width: 480px) {
  .styled-about-content {
    padding: 0 20px;
  }

  .about-text {
    font-size: 0.9rem;
    line-height: 1.5;
  }

  .about-text p:first-of-type {
    font-size: 1.1em;
    padding-top: 20px;
  }

  .sentence-red-first::first-letter {
    font-size: 1.3em;
  }

  .list-items li {
    line-height: 1.3;
    padding: 6px;
    margin-bottom: 8px;
  }
}

html, body {
  height: 100%;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 50vh;
}

.main-content {
  flex: 1;
}

.styled-footer {
  background-color: #efeeec;
  color: #5a405b;
  text-align: center;
  width: 100%;
  padding: 0.25rem 0;
  margin-top: auto;
}

.social-icons {
  display: flex;
  justify-content: center;
  padding: 0.25rem;
  gap: 0.25rem;
}

.social-icons a {
  color: #5a405b;
  font-size: 1rem;
  transition: color 0.3s;
}

.social-icons a:hover {
  color: #efeeec;
}

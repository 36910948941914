.overview-container {
    padding: 20px;
    font-family: Arial, sans-serif;
  }

  .carousel-wrapper{
    width: 100%;
  }

  .carousel.carousel-slider{
    height: 100%;
    background-color: transparent;
  }
  
  .back-button {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  .overview-header {
    text-align: center;
  }
  
  .overview-header h1 {
    font-size: 28px;
    margin-bottom: 10px;
  }
  
  .map-link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
    cursor: pointer;
  }
  
  .map-link img {
    width: 20px;
    margin-right: 5px;
  }
  
  address {
    font-style: normal;
    color: gray;
  }
  
  .overview-images {
    display: flex;
    gap: 10px;
    overflow-x: auto;
    margin: 20px 0;
    width: 100%;
  }
  
  .overview-images img {
    width: 30%;
    border-radius: 5px;
    padding: 5% 0 5% 0;
    height: 400px;
  }
  
  .overview-description {
    margin-top: 20px;
  }
  
  .overview-description p {
    font-size: 16px;
    line-height: 1.5;
  }
  
  .overview-description ul {
    list-style: disc;
    margin: 10px 0 0 20px;
  }
  
  .book-button {
    display: block;
    width: 100%;
    /* max-width: max-content; */
    padding: 10px;
    background-color: #3EAD9A;
    color: white;
    font-size: 18px;
    text-align: center;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .book-button:hover {
    background-color: #6285af;
  }
  

  /* For screens smaller than 768px (tablets, small laptops) */
@media screen and (max-width: 768px) {
    .overview-header h1 {
      font-size: 24px;
    }
  
    .overview-images {
      flex-direction: column;
      align-items: center;
    }
  
    .overview-images img {
      width: 80%;  /* Make images larger on smaller screens */
      height: 200px; /* Maintain aspect ratio */
      padding: 0;
      margin-bottom: 10px; /* Space between images */
    }
  
    .overview-description p {
      font-size: 14px;
    }
  
    .book-button {
      font-size: 16px;
    }
  
    .back-button {
      font-size: 16px;
      margin-bottom: 15px;
    }
  }
  
  /* For screens smaller than 480px (mobile devices) */
@media screen and (max-width: 480px) {
    .overview-header h1 {
      font-size: 20px;
    }
  
    .overview-description p {
      font-size: 14px;
    }
  
    .overview-images img {
      width: 100%; /* Take up full width on mobile */
      height: 200px; /* Maintain aspect ratio */
    }
  
    .book-button {
      font-size: 14px;
      padding: 12px;  /* Slightly larger padding for mobile */
    }
  
    .back-button {
      font-size: 14px;
      margin-bottom: 10px;
    }
  
    /* Adjust the carousel for mobile */
    .carousel .carousel-slider {
      display: block; /* Make the slider a block to ensure only one image is shown */
    }
  
    .carousel .slide {
      width: 100% !important; /* Ensure each slide takes full width */
      height: auto !important; /* Maintain aspect ratio */
    }
  
    .overview-images {
      width: 100%; /* Ensure images are properly aligned */
      gap: 10px;
    }
  }
  
.banner-container {
  display: flex;
  flex-direction: row; 
  align-items: center;
  justify-content: space-between; 
  color: #d7cfe8;
  background-color: #271d3b;
  box-shadow: 0 2px 5px #271d3b;
  top:0;
}

.nav-links {
  font-family: 'Brush Script MT', cursive;
  display: flex;
  margin-right: 20px;
  gap: 10px; 
  align-items: center;
  justify-content: flex-end;  
}

.nav-links a {
  color: #d7cfe8;
  font-weight: bold;
  text-decoration: none;
  font-size: 1rem; 
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: #3ead9a;
}

.banner-icon-container {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
}

.banner-icon {
  width: 30px;
  height: 30px;
}


@media (min-width: 768px) {
  .banner-container {
    flex-direction: row; 
    align-items: center;
    justify-content: space-between; 
  }

  .nav-links {
    gap: 20px;
    margin-bottom: 0;
    flex: 1; 
    justify-content: flex-end; 
  }

  .nav-links a {
    font-size: 1.2rem;
  }

  .banner-icon-container {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }

  .banner-icon {
    width: 100px;
    height: 100px;
  }
}

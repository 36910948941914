.rest-home {
    background-color: #f5f5f5;
    margin: 0;
    padding-top: 40px;
}

/* Welcome heading */
.rest-home .bold {
    font-weight: bold;
    color: #d7cfe8;
    display: block;
    font-size: 2.5rem;
}

.camp-jk-summary {
    padding: 20px;
    text-align: center;
}

.heading {
    font-family: 'Brush Script MT', cursive;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
    color: #140f21;
}

.body {
    display: block;
    font-size: 1.2rem;
    line-height: 1.5;
    text-align: center;
    margin: 0 auto;
    color: #140f21;
}

.more-camp-info {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three columns for larger screens */
    gap: 8px;
    padding: 20px;
    margin-top: 20px;
    padding-bottom: 40px;
}

.more-camp-info .camp-info-item {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.more-camp-info img {
    width: 50%;
    height: auto;
    object-fit: cover;
    padding-left: 25%;
}

.more-camp-info .image-summary {
    margin-top: 2px;
    font-size: 1rem;
    line-height: 1.4;
    text-align: center;
    color: #140f21;
}

/* Media Queries for Responsiveness */

/* Medium screens (tablets, smaller laptops) */
@media (max-width: 1024px) {
    .rest-home .bold {
        font-size: 2rem;
    }

    .heading {
        font-size: 1.8rem;
    }

    .body {
        font-size: 1rem;
    }

    .more-camp-info {
        grid-template-columns: repeat(2, 1fr); /* Two columns */
        gap: 12px;
    }

    .more-camp-info img {
        width: 60%;
        padding-left: 20%;
    }
}

/* Small screens (phones) */
@media (max-width: 768px) {
    .rest-home .bold {
        font-size: 1.5rem;
    }

    .heading {
        font-size: 1.6rem;
    }

    .body {
        font-size: 0.9rem;
        line-height: 1.4;
    }

    .more-camp-info {
        grid-template-columns: 1fr; /* One column */
        gap: 10px;
    }

    .more-camp-info img {
        width: 80%;
        padding-left: 10%;
    }
}

/* Extra small screens (very narrow phones) */
@media (max-width: 480px) {
    .rest-home .bold {
        font-size: 1.2rem;
    }

    .heading {
        font-size: 1.4rem;
    }

    .body {
        font-size: 0.8rem;
    }

    .more-camp-info img {
        width: 100%;
        padding-left: 0;
    }
}

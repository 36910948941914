/* General Carousel Styles */
.carousel {
    margin-top: 130px;
    margin-bottom: 0px;
    overflow: hidden; /* Ensure no overflow */
}

.carousel-wrapper .slide img {
    height: 300px;
    object-fit: cover;
    border-radius: 10px; /* Smooth rounded corners */
    box-shadow: 0 4px 10px #271d3b; /* Subtle shadow for depth */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition on hover */
}

.carousel-wrapper {
    display: flex;
    justify-content: center; /* Centers the carousel */
    align-items: center;
    gap: 20px; /* Adds spacing between slides */
    padding: 10px 0; /* Adds top and bottom padding */
}

.carousel-slide{
    margin: 0 10px;
    padding: 20px 0;
}   

.carousel-wrapper .carousel-slide:hover {
    transform: scale(1.05); /* Slight zoom effect */
}

@media (max-width: 768px) {
    .carousel {
        margin-top: 10px;
        margin-bottom: 0px;
    }

    .carousel-wrapper .slide img {
        height: auto; /* Adjusts height automatically to maintain aspect ratio */
        max-height: 200px; /* Sets a max height to avoid oversized images */
        object-fit: contain; /* Ensures the image fits within the container without cropping */
    }
}







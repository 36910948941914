.map-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-bottom: 8%;
  box-sizing: border-box;
  background-color: #f5f5f5;
  background: linear-gradient(to bottom left, #e5e0f0 50%, #d7cfe8 50%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  position: relative;
}

.map-info {
  text-align: center;
  padding-bottom: 60px;
  padding-top: 30px; 
}

.map-header {
  font-size: 2em;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px; 
  padding-right: 130px;
  margin-top: 20px; 
}

.map-body {
  font-size: 0.8em;
  color: #555;
  margin-top: 20px; 
  margin-left: 10px;
}

.map {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 800px;
}

@media (max-width: 768px) {
  .map-header {
    font-size: 1.5em;
    padding-right: 10px;
  }

  .map-body {
    font-size: 1em;
  }

  .map {
    max-width: 100%;
    padding: 0px;
  }

  .map-container {
    padding-bottom: 5%;
  }

  .map-info {
    padding-top: 20px;
  }
}

@media (max-width: 480px) {
  .map-header {
    font-size: 1.2em;
    padding-right: 10px;
    color: orchid;
  }

  .map-body {
    font-size: 0.9em;
    display: none;
  }

  .map {
    max-width: 100%;
    padding: 0 5px;
  }

  .map-container {
    padding-bottom: 10%;
  }

  .map-info {
    padding-top: 10px; 
  }
}

/* Entire Page */
.home-container {
    display: flex;
    flex-direction: column;
    min-height: max-content;
    background-image: url("../../assets/logo/name.png");
    background-repeat: no-repeat;
    background-size: 100%, 100%;
}

/* Everything until book now button */
.styled-home-content {
    color: #5a405b;
    text-align: center;
    flex: 1; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    /* z-index: 1;  */
}

/* Text in background image */
.home-text {
    margin: 0% 10%;
    padding-top: 40%;
    color: #d7cfe8;
}

/* Book Now button */
.contact-button {
    color: white;
    font-weight: bold;
    background-color: #3EAD9A;
    cursor: pointer;
    padding: 10px 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    margin-top: 15px;
    max-width: max-content;
    position: relative; /* Changed to relative */
    margin: 20px auto;  /* Centered the button horizontally */
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-button:hover {
    background-color: #6285af;
}

/* Book Now Rotating image */
.contact-button img {
    margin-left: 10px; /* Add some spacing between the text and icon */
    transition: transform 0.3s ease; /* Smooth transition for rotation */
    height: 20px;
    vertical-align: middle; /* Vertically align the icon with the text */
    color: #3ead9a;
}
  
.contact-button .rotated {
    transform: rotate(180deg); /* Rotate the icon when the button text is 'Close' */
}


/* The three different booking options view */
.info {
    transition: max-height 0.3s ease-in-out;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    gap: 10%;
    padding-top: 0%;
    margin: 20px;
    align-items: center;
    justify-content: center;
  }
  
  .one,
  .two,
  .three {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .one img,
  .two img,
  .three img {
    width: 100%;
    max-width: 300px;
    height: auto;
    border-radius: 8px;
  }
  
  .summary {
    margin-top: 10px;
    font-size: 14px;
    color: #555;
  }
  
  .reserve-button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #3EAD9A;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .reserve-button:hover {
    background-color: #6285af;
  }
  

/* Fullscreen image click */
.fullscreen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .fullscreen-image {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
  }
  
  .fullscreen-overlay:hover {
    cursor: pointer;
  }

  /* Medium screens (760px to 960px) */
@media (min-width: 760px) and (max-width: 960px) {
    .home-container {
        padding: 20px;
    }



    .home-text {
        padding-top: 65%; /* Adjust for medium screens */
        color: black;
    }

    .info {
        flex-direction: row; /* Keep items in a row */
        flex-wrap: wrap; /* Allow wrapping */
        gap: 15px; /* Adjust spacing between options */
    }

    .one,
    .two,
    .three {
        max-width: 45%; /* Ensure items don't exceed half the width */
        margin-bottom: 15px; /* Add spacing for wrapping */
    }

    .one img,
    .two img,
    .three img {
        max-width: 90%; /* Scale down images slightly */
        height: auto; /* Maintain aspect ratio */
    }

    .reserve-button {
        padding: 8px 16px;
        font-size: 0.9rem; /* Adjust button size */
    }
}


/* Media Queries */
@media (max-width: 768px) {
    .home-container {
        padding: 10px;
    }

    .styled-home-content {
        padding: 10px;
    }

    .home-text {
        padding-top: 60%;
        margin: 10px;
        font-size: 1rem; /* Scale text size */
        color: black;
    }

    .contact-button {
        padding: 8px 16px;
        font-size: 0.9rem;
    }

    .info {
        flex-wrap: wrap; /* Allow items to wrap on smaller screens */
        gap: 20px;
    }

    .one,
    .two,
    .three {
        max-width: 100%; /* Allow full width */
        margin-bottom: 20px; /* Add spacing for wrapping */
    }
}

@media (max-width: 480px) {
    .home-text {
        padding-top: 70%;
        font-size: 0.8rem;
        color: black;
    }

    .contact-button {
        padding: 6px 12px;
        font-size: 0.8rem;
    }

    .summary {
        font-size: 12px;
    }

    .info {
        gap: 10px;
    }
}
